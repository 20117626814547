import React from 'react';
import Loadable from 'react-loadable';
import PrivateProvider from '@/components/providers/PrivateProvider';
import HeaderMetadata from '@/layouts/HeaderMetadata';
import { LoadingPage } from '@/components/loading/loadingBase';

const AgentLookupLoadable = Loadable({
  loader: () => import('@/sections/AgentLookup'),
  loading: (props) => <LoadingPage isLoading={true} error={props.error} />,
});
const AgentLookupPage: React.FC = () => {
  return (
    <React.Fragment>
      <HeaderMetadata
        title="Agent Lookup"
        description="GF Template is a general web template for creating another project"
      />
      <PrivateProvider>
        <AgentLookupLoadable />
      </PrivateProvider>
    </React.Fragment>
  );
};

export default AgentLookupPage;
